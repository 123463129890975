import { useContext, useEffect } from "react";
import Header from "./Header";
import Bridge from "./Bridge/Bridge";
import Modal from "./Modal";
import ModalContext from "../../store/ModalContextProvider";
import SideBar from "./Bridge/SideBar";
import FaucetSidebarSVG from "../svgs/FaucetSidebarSVG";
import Faucet from "./Faucet/Faucet";
import { getSubdomain } from "../../utils";

export enum Page {
	Bridge = "bridge",
	Faucet = "faucet",
}

const Main: React.FC = () => {
	const subdomain = getSubdomain();

	const modalContext = useContext(ModalContext);

	const renderModal = () => {
		return (
			(modalContext.error || modalContext.message) && (
				<Modal
					message={modalContext.message}
					setMessage={modalContext.setMessage}
					error={modalContext.error}
					setError={modalContext.setError}
					isPending={modalContext.isPending}
				/>
			)
		);
	};

	const BridgeEntryPoint: React.FC = () => {
		return (
			<>
				{renderModal()}
				<SideBar
					sideBarTitle="Bridge"
					className="hidden xl:block min-w-[25%] bg-nuetral-100"
				/>
				<div className="w-full md:col-start-2 col-span-2 md:mx-4 md:mt-1 mt-20 ">
					<Header />
					<Bridge />
				</div>
				<div className="xl:flex xl:min-w-[25%] flex items-end">
					<img
						src="/images/bridge.png"
						alt="Bridge Logo"
						className="hidden xl:block"
					/>
				</div>
			</>
		);
	};

	const FaucetEntryPoint: React.FC = () => {
		return (
			<>
				{renderModal()}
				<SideBar
					sideBarTitle="Faucet"
					className="hidden xl:block min-w-[25%] bg-nuetral-100"
				/>
				<div className="w-full md:col-start-2 md:col-span-2 mt-40 md:mx-4">
					{/* <Header isFaucet /> */}
					<Faucet />
				</div>
				<div className="hidden xl:flex min-w-[25%] flex items-end">
					<FaucetSidebarSVG />
				</div>
			</>
		);
	};

	const getPage = (subdomain: string) => {
		switch (subdomain) {
			case Page.Bridge:
				return <BridgeEntryPoint />;
			case Page.Faucet:
				return <FaucetEntryPoint />;
			default:
				return <BridgeEntryPoint />;
		}
	};

	useEffect(() => {
		document.title = `Botanix ${subdomain === Page.Faucet ? "Faucet" : "Bridge"}`;
	}, [subdomain]);

	return (
		<main className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 bg-grey w-full h-screen">
			{getPage(subdomain)}
		</main>
	);
};
export default Main;
