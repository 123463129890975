import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import AppContext from '../../../store/AppContextProvider'
import config from '../../../config'

const SuccessPage: React.FC = () => {
	const context = useContext(AppContext)

	return (
		<div className='bg-white p-10 w-full rounded-3xl shadow-2xl'>
			<div className='flex-col items-center text-xl'>
				<div className='flex items-center mb-5'>
					<FontAwesomeIcon icon={faCheck} size='2x' />
					<p className='ml-8'>
						Success! Your withdraw transaction has been sent.
					</p>
				</div>
				<div className='flex my-5'>
					<p>Withdraw Amount: </p>
					<p className='ml-2 font-bold'>{context.withdrawAmount} BTC</p>
				</div>
				<p>Address:</p>
				<div className='w-full mb-5 p-2 bg-neutral-100 rounded-3xl font-bold text-center truncate'>
					<a
						className='underline'
						href={`https://mempool.space/signet/address/${context.receiveAddress}`}
						target='_blank'
						rel='noreferrer'
					>
						{context.receiveAddress}
					</a>
				</div>
				<div>
					<a
						href={config.BOTANIX_DOCS_URL}
						target='_blank'
						rel='noreferrer'
						className='underline'
					>
						Read about how withdraws from Botanix work.
					</a>
				</div>
				<div className='flex justify-end w-full mt-10'>
					<button
						className='w-1/6 bg-yellow p-2 rounded-2xl'
						onClick={() => {
							context.setIsSuccess(false)
							context.setToggle(false) // send user to start
						}}
					>
						<div className='flex justify-around items-center'>
							<span className='text-neutral-600 text-sm font-medium font-poppins'>
								CLOSE
							</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	)
}

export default SuccessPage
