import React, { ReactNode } from "react";

interface WrapperProps {
	url: string;
	children: ReactNode;
}
export const IconWrapper = ({ children, url }: WrapperProps) => {
	return (
		<a href={url} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
};
