import { TransactionError } from './types/types'

export const parseErrorMessage = (error: TransactionError) => {
	let errorMessage = ''
	if (error.reason) {
		errorMessage = error.reason
	} else if (error.message) {
		errorMessage = error.message
	}
	if (typeof error === 'string') {
		errorMessage = error
	}

	return errorMessage
}

export const getSubdomain = () => {
	const host = window.location.host
	const subdomain = host.split('.')[0]

	return subdomain
}
