import { useContext, useEffect, useState } from "react";
import AddBotanixWrapper from "../AddBotanixWrapper";
import RightArrow from "../../svgs/RighArrowSVG";
import { ethers } from "ethers";
import config from "../../../config";
import ModalContext from "../../../store/ModalContextProvider";
import { TransactionError } from "../../../types/types";
import { parseErrorMessage } from "../../../utils";
import AppContext from "../../../store/AppContextProvider";
import faucetAbi from "../../../contracts/faucetAbi";
import FeedbackButton from "../FeedbackButton";

const FaucetContent: React.FC = () => {
  const [withdrawAmount, setWithdrawAmount] = useState<string>("0");
  const [faucet, setFaucet] = useState<ethers.Contract | null>(null);

  const appContext = useContext(AppContext);
  const modalContext = useContext(ModalContext);

  const requestfunds = async () => {
    // @ts-ignore
    const grecaptcha = window.grecaptcha;
    if (!grecaptcha) {
      modalContext.setMessage(null);
      modalContext.setIsPending(false);
      modalContext.setError("Failed to load Captcha Library. Please check your connection");
      return;
    }
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(
        "6LefRlkpAAAAAEpRAh_VLD6eaRwVbDOksvELWaNN",
        { action: "submit" }
      );

      try {
        if (appContext.account) {
          modalContext.setMessage("Requesting funds...");
          modalContext.setIsPending(true);

          const response = await fetch(`${config.SIDE_CAR_URL}/api/funds`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              // TODO(armins) set this after k8 migration is done
              // captchaToken: token,
              ethereumAddress: appContext.account.slice(2),
            }),
          });

          if (!response.ok) {
            modalContext.setMessage(null);
            // assuming time lock error bc error modal shows on load if faucet is out of funds
            const timeLock = await faucet?.lockTime();
            // convert seconds to hours
            const time = timeLock.div(3600).toNumber();
            // TODO: have better unit formatter for when time lock is hours or a day
            throw new Error(
              `Can only request funds every ${time} hour${
                time === 1 ? "" : "s"
              }.`
            );
          } else {
            modalContext.setMessage(
              "Success! Your funds are on the way. It could take up to 5 minutes."
            );
          }
          modalContext.setIsPending(false);
        }
      } catch (error) {
        modalContext.setMessage(null);
        modalContext.setIsPending(false);
        const message = parseErrorMessage(error as TransactionError);
        modalContext.setError(message);
      }
    });
  };

  useEffect(() => {
    if (window.ethereum) {
      if (!faucet) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const faucetContract = new ethers.Contract(
          config.FAUCET_CONTRACT_ADDRESS,
          faucetAbi,
          signer
        );
        setFaucet(faucetContract);
        // Set withdraw amount
        faucetContract?.withdrawAmount().then((amount: number) => {
          setWithdrawAmount(amount.toString());
        }).catch((error: Error) => {
          console.error(error);
          modalContext.setError("Unable to interact with faucet contract. Please try again later.");
        })
      }
    }
  }, [faucet, modalContext]);

	const GetFunds: React.FC = () => {
		return (
			<>
				<div className='h-8 mb-10 text-center text-neutral-600 text-base font-normal font-heebo'>
					<p>Get your funds by clicking on the button below</p>
					<p>(once every 48 hours)</p>
				</div>
				<div className='flex h-16 mb-5 justify-around items-center bg-neutral-100 rounded-3xl'>
					<div className='text-neutral-600 text-base font-normal font-poppins'>
						You will get:
					</div>
					<div className='flex w-2/3 h-16 pl-20 justify-start items-center bg-neutral-100 rounded-3xl'>
						<img
							className='ml-6'
							src='/images/bitcoin-logo.png'
							alt='Bitcoin Logo'
						/>
						<p className='ml-10 text-neutral-600 text-base font-bold font-poppins'>
							{/* Get amount dynamically from faucet contract */}
							{ethers.utils.formatEther(withdrawAmount)}{' '}
							<span className='font-normal'>BTC</span>
						</p>
					</div>
				</div>
				<button
					className='flex h-16 w-full justify-center items-center bg-amber-400 rounded-3xl'
					onClick={requestfunds}
				>
					<div className='mr-5'>GET YOUR FUNDS</div>
					<RightArrow />
				</button>
			</>
		)
	}

  return (
    <div className="flex-col bg-white">
      <GetFunds />
    </div>
  );
};

const Faucet: React.FC = () => {
  return (
    <>
      <AddBotanixWrapper isFaucet>
        <FaucetContent />
      </AddBotanixWrapper>
      <div className={`flex justify-start w-full mt-20`}>
        <div className="flex flex-col justify-end w-1/6 xl:hidden">
          <FeedbackButton />
        </div>
      </div>
    </>
  );
};

export default Faucet;
