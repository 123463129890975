import { useContext } from "react";
import AppContext from "../../store/AppContextProvider";
import ToggleButton from "./ToggleButton";
import config from "../../config";
import BotanixLogo from "./BotanixLogo";

interface HeaderProps {
	isFaucet?: boolean;
}

const AddBotanixHeader: React.FC<HeaderProps> = ({ isFaucet = false }) => {
	const context = useContext(AppContext);
	const isConnectedToBotanixTestnet =
		context.chainId === config.BOTANIX_TESTNET_CHAID_ID;

	return (
		<div
			className={`w-full px-2 ${isConnectedToBotanixTestnet ? "py-6" : "py-0"}`}
		>
			<div className="flex h-16 w-full justify-center">
				<BotanixLogo isTablet />
				{/* {isConnectedToBotanixTestnet && !isFaucet && (
					<ToggleButton
						className="flex justify-center items-center px-6 h-14 bg-zinc-300 bg-opacity-0 rounded-3xl border border-black"
						buttonConfig={{
							b1Name: "Deposit",
							b2Name: "Withdraw",
							b1Style: "py-2 px-4 bg-amber-400 rounded-3xl text-label",
							b2Style: "py-2 px-4 text-label",
						}}
						toggle={context.toggle}
						setToggle={context.setToggle}
					/>
				)} */}
			</div>
		</div>
	);
};

export default AddBotanixHeader;
