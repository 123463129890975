import React, {
	MouseEventHandler,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { Children, Ethereum, TransactionError } from '../types/types'
import detectEthereumProvider from '@metamask/detect-provider'
import MetaMaskOnboarding from '@metamask/onboarding'
import validate, { getAddressInfo } from 'bitcoin-address-validation'
import copy from 'copy-to-clipboard'
import { BigNumber, ethers } from 'ethers'
import mintingAbi from '../contracts/mintingAbi'
import ModalContext from './ModalContextProvider'
import config from '../config'
import { Web3Provider } from '@ethersproject/providers'
import { parseErrorMessage } from '../utils'

type GatewayAddressResponse = {
	gatewayAddress: string
}

const ethereum = window.ethereum as Ethereum
const withdrawTransactionGas = 30000

interface Context {
	account: string
	toggle: boolean
	setToggle: React.Dispatch<React.SetStateAction<boolean>>
	chainId: number
	gatewayAddress: string
	getGatewayAddress: (captchaToken: string) => void
	balance: string
	withdrawAmount: string
	setWithdrawAmount: React.Dispatch<React.SetStateAction<string>>
	receiveAddress: string
	receiveAddressHandler: (address: string) => void
	btcFees: string
	addBotanixTestnetChain: () => void
	withdrawFunds: () => void
	withdrawCost: BigNumber
	copyToClipboard: MouseEventHandler<HTMLButtonElement>
	isSuccess: boolean
	setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
	validateMessage: string
	peginTxHash: string, 
	setPeginTxHash: React.Dispatch<React.SetStateAction<string>>
}

// Shared state for Bridge components
const AppContext = React.createContext<Context>({
	account: '',
	toggle: false,
	setToggle: () => {},
	chainId: 0,
	gatewayAddress: '',
	getGatewayAddress: (captchaToken: string) => {},
	balance: '0',
	withdrawAmount: '0',
	setWithdrawAmount: () => {},
	receiveAddress: '',
	receiveAddressHandler: () => {},
	btcFees: '0',
	addBotanixTestnetChain: () => {},
	withdrawFunds: () => {},
	withdrawCost: BigNumber.from(0),
	copyToClipboard: () => {},
	isSuccess: false,
	setIsSuccess: () => {},
	validateMessage: '',
	peginTxHash: '',
	setPeginTxHash: () => {},
})

const isError = (error: string) => {
	return (
		!error.includes('eth_requestAccounts') &&
		!error.includes('wallet_requestPermissions') &&
		!error.includes('User rejected the request')
	)
}

// Manages state for Bridge components
// TODO: separate into different contexts
export const AppContextProvider: React.FC<Children> = ({ children }) => {
	const [account, setAccount] = useState<string>('')
	const [toggle, setToggle] = useState<boolean>(false)

	// Surface errors throughout app
	const modalContext = useContext(ModalContext)

	/**  BridgeContextProvider state **/
	const [provider, setProvider] = useState<Web3Provider | null>(null)

	/**  Main and Header state **/
	const [chainId, setChainId] = useState<number>(0)

	/** Bridge state **/
	const [isSuccess, setIsSuccess] = useState<boolean>(false)

	/** Bridge and Pegin state **/
	const [gatewayAddress, setGatewayAddress] = useState<string>('')

	/** Pegout state **/
	const [balance, setBalance] = useState<string>('0')
	const [withdrawAmount, setWithdrawAmount] = useState<string>('0') // in ether
	const [receiveAddress, setReceiveAddress] = useState<string>('')
	const [btcFees, setBtcFees] = useState<string>('0')
	const [validateMessage, setValidateMessage] = useState<string>(
		'Invalid withdraw amount'
	)
	const [peginTxHash, setPeginTxHash] = useState<string>(
		''
	)

	const [withdrawCost, setWithdrawCost] = useState<BigNumber>(BigNumber.from(0))

	/** BridgeContextProvider handlers **/
	// assumes Metamask is connected
	const getChainId = async () => {
		if (ethereum) {
			const chainIdHex = (await ethereum.request({
				method: 'eth_chainId',
			})) as string

			return parseInt(chainIdHex, 16)
		}
	}

	const getAccount = useCallback(async () => {
		try {
			const accounts = (await ethereum.request({
				method: 'eth_requestAccounts',
			})) as string[]
			const provider = new ethers.providers.Web3Provider(window.ethereum)
			setProvider(provider)

			if (accounts.length !== 0 && accounts[0] !== '') {
				setAccount(accounts[0])
			}
		} catch (error) {
			// prevent error message from metamask about eth_requestAccounts
			const errorString = JSON.stringify(error)
			if (isError(errorString)) {
				modalContext.setError(errorString)
			} else {
				modalContext.setError(null)
			}
		}
	}, []) // intentionally empty dependency array

	const getBalance = useCallback(async () => {
		const balance = await provider?.getBalance(account)
		if (balance) {
			const balanceString = ethers.utils.formatEther(balance ?? 0) ?? '0'
			setBalance(balanceString)
		}
	}, [account, provider])

	const validateWithdrawAmount = useCallback(
		(amount: string) => {
			const parsedAmount = parseFloat(amount)
			const etherBalance = parseFloat(balance)
			switch (true) {
				case parsedAmount <= 0:
					setValidateMessage('Amount must be greater than 0')
					break
				case parsedAmount > etherBalance:
					setValidateMessage('Amount exceeds balance')
					break
				case parsedAmount <= etherBalance:
					setValidateMessage('success')
					break
				default:
					setValidateMessage('Invalid amount.')
					break
			}
		},
		[balance]
	)

	const setWithrawTransactionCost = useCallback(
		async (btcFees: BigNumber) => {
			if (provider) {
				const gasPrice = await provider.getGasPrice()
				const gasCost = BigNumber.from(withdrawTransactionGas)
					.mul(gasPrice)
					.add(btcFees)
				setWithdrawCost(gasCost)
			}
		},
		[provider]
	)

	const getBtcFees = useCallback(async () => {
		const provider = new ethers.providers.JsonRpcProvider(config.PROVIDER_URL)
		const feesHex = (await provider.send('eth_getBtcFeeRate', [])) as string
		const fees = parseInt(feesHex, 16).toString()

		// TODO: add more validation checks on fees
		if (fees) {
			setBtcFees(fees)
			setWithrawTransactionCost(BigNumber.from(fees))
		}
	}, [setWithrawTransactionCost])

	/** Main, Header, and Pegout handlers **/
	const addBotanixTestnetChain = async () => {
		const provider = await detectEthereumProvider()
		if (provider && provider.isMetaMask) {
			try {
				await ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [
						{
							chainId: `0x${config.BOTANIX_TESTNET_CHAID_ID.toString(16)}`,
							chainName: 'Botanix Testnet',
							nativeCurrency: {
								name: 'Botanix',
								symbol: 'BTC',
								decimals: 18,
							},
							rpcUrls: [config.PROVIDER_URL],
							blockExplorerUrls: [config.BLOCK_EXPLORER_URL],
						},
					],
				})

				setChainId(config.BOTANIX_TESTNET_CHAID_ID)

				await getAccount()
			} catch (error) {
				const errorString = JSON.stringify(error)
				if (isError(errorString)) {
					modalContext.setError(errorString)
				} else {
					modalContext.setError(null)
				}
			}
		} else {
			// start onboarding if Metamask is not installed
			if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
				const onboarding = new MetaMaskOnboarding()
				onboarding.startOnboarding()
			}
		}
	}

	/** Pegin handlers  **/
	const getGatewayAddress = async (captchaToken: string) => {
		if (provider) {
			modalContext.setMessage('Getting your gateway address.')
			modalContext.setIsPending(true)

			const errorMessage = 'Error sending data to server.'
			try {
				const response = await fetch(
					`${config.SIDE_CAR_URL}/api/gatewayAddress`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							// TODO(armins) set this after k8 migration is done
							// captchaToken,
							ethereumAddress: account.slice(2),
						}),
					}
				)

				if (!response.ok) {
					modalContext.setMessage(null)
				} else {
					modalContext.setMessage('Success!')
				}
				modalContext.setIsPending(false)

				const responseData =
					(await response.json()) as unknown as GatewayAddressResponse

				setGatewayAddress(responseData.gatewayAddress)
			} catch (error) {
				console.log(error)
				modalContext.setMessage(null)
				modalContext.setIsPending(false)
				modalContext.setError(errorMessage)
			}
		}
	}

	const copyToClipboard: MouseEventHandler<HTMLButtonElement> = (event) => {
		if (gatewayAddress !== '') {
			copy(gatewayAddress)
			modalContext.setMessage('Copied to clipboard!')
			setGatewayAddress('')
		}
	}

	/** Pegout state handlers **/
	const receiveAddressHandler = (address: string) => {
		if (address && validate(address)) {
			console.log(getAddressInfo(address))
			setReceiveAddress(address)
		} else {
			modalContext.setError('Invalid address.')
		}
	}

	const wrongNetworkError = 'Please connect to the Botanix Testnet.'
	const withdrawFunds = async () => {
		// check still connected to Botanix Testnet
		// to avoid transacting on wrong network (ie Ethereum Mainnet)
		try {
			const chainId = await getChainId()
			if (chainId !== config.BOTANIX_TESTNET_CHAID_ID) {
				throw new Error(wrongNetworkError)
			}

			if (withdrawAmount && receiveAddress) {
				if (validateMessage !== 'success') {
					modalContext.setError(validateMessage)
				}

				modalContext.setMessage('Withdrawing funds...')
				modalContext.setIsPending(true)

				const provider = new ethers.providers.Web3Provider(window.ethereum)
				const signer = provider.getSigner()
				const mintingContract = new ethers.Contract(
					config.GENESIS_CONTRACT_ADDRESS,
					mintingAbi,
					signer
				)

				const destination = ethers.utils.hexlify(
					ethers.utils.toUtf8Bytes(receiveAddress)
				)
				const metadata = '0x'

				const amountInWei = ethers.utils.parseUnits(withdrawAmount, 'ether')
				const transaction = await mintingContract.burn(destination, metadata, {
					value: amountInWei,
				})
				console.log(`transaction: ${JSON.stringify(transaction)}`)

				const receipt = await transaction.wait()
				console.log(`receipt: ${JSON.stringify(receipt)}`)
				modalContext.setMessage(null)
				modalContext.setIsPending(false)
				setIsSuccess(true)
			} else {
				modalContext.setError('Please enter a valid amount and address.')
			}
		} catch (error) {
			modalContext.setMessage(null)
			modalContext.setIsPending(false)
			const message = parseErrorMessage(error as TransactionError)
			modalContext.setError(message)
		}
	}

	// set all pegin/pegout forms to default values
	const resetForms = useCallback(() => {
		setWithdrawAmount('0')
		setReceiveAddress('')
		setGatewayAddress('')
	}, [])

	const handleAccountsChanged = useCallback(() => {
		getAccount()
		resetForms()
	}, [getAccount, resetForms])

	// set chainId on load if exists
	useEffect(() => {
		if (ethereum) {
			getChainId().then((chainId) => {
				setChainId(chainId ?? 0)
			})
		}
	}, [])

	// set and clean up listener
	useEffect(() => {
		if (window.ethereum) {
			window.ethereum.on('accountsChanged', handleAccountsChanged)

			return () => {
				window.ethereum.removeListener('accountsChanges', handleAccountsChanged)
			}
		}
	}, [handleAccountsChanged])

	useEffect(() => {
		if (chainId === config.BOTANIX_TESTNET_CHAID_ID) {
			handleAccountsChanged()
		}
	}, [account, chainId, handleAccountsChanged])

	useEffect(() => {
		if (chainId === config.BOTANIX_TESTNET_CHAID_ID && account === '') {
			getAccount()
		}
	}, [account, chainId, getAccount])

	useEffect(() => {
		if (account !== '') {
			getBalance()
		}
	}, [account, balance, getBalance])

	useEffect(() => {
		const identifier = setTimeout(() => {
			if (withdrawAmount !== '0') {
				validateWithdrawAmount(withdrawAmount)
			}
		}, 1000)

		return () => clearTimeout(identifier)
	}, [validateWithdrawAmount, withdrawAmount])

	useEffect(() => {
		getBtcFees()
	}, [btcFees, getBtcFees])

	const contextValue = {
		account,
		toggle,
		setToggle,
		chainId,
		gatewayAddress,
		getGatewayAddress,
		balance,
		withdrawAmount,
		setWithdrawAmount,
		receiveAddress,
		receiveAddressHandler,
		btcFees,
		addBotanixTestnetChain,
		withdrawFunds,
		withdrawCost,
		copyToClipboard,
		isSuccess,
		setIsSuccess,
		validateMessage,
		peginTxHash,
		setPeginTxHash,
	}

	return (
		<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
	)
}

export default AppContext
