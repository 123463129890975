const Steps: React.FC = () => {
	return (
		<div className='flex bg-white w-full mt-20 p-10 rounded-3xl shadow-2xl'>
			<div className='flex justify-between items-center pl-6'>
				<div className='step-span-container'>
					<span>Info</span>
				</div>
				<div className='flex flex-col step-text'>
					<p>
						Use the QR Code or copy the address to send signet Bitcoin to Botanix.{' '}
					</p>
					<p>It will take up to 60 minutes for your funds to arrive.</p>
					<p className='font-semibold'>Make sure your Bitcoin transactions are sent through the Signet network.</p>
				</div>
			</div>
		</div>
	)
}

export default Steps
