import QRCode from "react-qr-code";
import { useCallback, useContext, useState } from "react";
import AppContext from "../../../store/AppContextProvider";
import CopyFull from "../../svgs/CopyFull";
import AddBotanixWrapper from "../AddBotanixWrapper";
import ModalContext from "../../../store/ModalContextProvider";
import { Tooltip } from "react-tooltip";
import config from "../../../config";

const PeginContent: React.FC = () => {
  const context = useContext(AppContext);
  const modalContext = useContext(ModalContext);

  const [txidInput, setTxidInput] = useState("");

  const handleVerifyTxid = useCallback(async () => {
    const txid = txidInput;
    // Easy client side checks
    // Check length
    if (txid.length !== 64) {
      modalContext.setMessage(null);
      modalContext.setIsPending(false);
      modalContext.setError("Invalid txid");
      return;
    }
    // Check if values are all hex
    const hexRegex = /^[0-9a-fA-F]+$/;
    if (!hexRegex.test(txid)) {
      modalContext.setMessage(null);
      modalContext.setIsPending(false);
      modalContext.setError("Invalid txid");
      return;
    }

    modalContext.setMessage("Validating Pegin Transaction...");
    modalContext.setIsPending(true);

    const response = await fetch(`${config.SIDE_CAR_URL}/api/pegin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // TODO(armins) add captcha token
      body: JSON.stringify({
        txid,
        gatewayAddress: context.gatewayAddress,
      }),
    });
    const json = await response.json();
    if (response.ok) {
      const txHash = json.txHash as string;
      context.setPeginTxHash(txHash);
      modalContext.setMessage(`Pegin success! Close to continue`);
    } else {
      let err = json.error;
      modalContext.setMessage(`${err}`);
    }
  }, [context, modalContext, txidInput]);

  const blockScoutLink = (txHash: string): string => {
    return `https://blockscout.botanixlabs.dev/tx/${txHash}`
  }

  return (
    <>
      {context.peginTxHash !== "" && (
        <div className="flex-col">
          <div className="flex-col">
            <div className="mb-2 3xl-text font-bold">
              Success! Your Pegin was completed and sent to the Botanix Chain.
            </div>
            <div className="mb-2 text text-orange">
              <a href={blockScoutLink(context.peginTxHash)}>View your Pegin Transaction</a>
            </div>
          </div>
        </div>
      )}
      {context.peginTxHash === "" && (
        <div className="flex-col">
          <div className="flex-col">
            <div className="mb-2 3xl-text font-bold">
              Step 1: Send Bitcoin Signet to your Deposit Address
            </div>
            <div className="flex justify-between">
              <div className="flex-col w-[25%] lg:w-[20%]">
                <div className="instructions-60 mb-2">Method 1 - Scan</div>
                <QRCode value={context.gatewayAddress} size={100} />
              </div>
              <div className="flex-col mb-4 w-[65%] lg:w-[75%]">
                <div className="instructions-60 mb-2">Method 2 - Copy</div>
                <div className="flex justify-center items-center w-[4/5] p-6 mb-6 bg-neutral-100 rounded-3xl text-center">
                  <input
                    className="w-full cursor-not-allowed bg-neutral-100 text-sm text-center font-poppins"
                    type="text"
                    value={context.gatewayAddress}
                    readOnly
                  />
                </div>
                <div className="w-1/6 bg-yellow p-2 rounded-2xl">
                  <button className="w-full" onClick={context.copyToClipboard}>
                    <div className="flex justify-around items-center">
                      <span className="text-neutral-600 text-xs font-medium font-poppins">
                        COPY
                      </span>
                      <CopyFull />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col mb-8">
            <div className="flex">
              <div className="mb-4 text-label">Corresponding EVM Address</div>
              <div className="ml-2 cursor-pointer">
                <div className="flex justify-center items-center w-6 h-6 bg-neutral-100 rounded-full text">
                  <a
                    className="my-anchor-element instructions-60"
                    href={config.BOTANIX_DOCS_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ?
                  </a>
                </div>
                <Tooltip
                  anchorSelect=".my-anchor-element"
                  place="top"
                  clickable
                >
                  This is the address that will receive Bitcoin on Botanix.{" "}
                  <br />
                  <a
                    href={config.BOTANIX_DOCS_URL}
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    Read the docs for more information.
                  </a>
                </Tooltip>
              </div>
            </div>
            <div className="flex items-center w-[4/5] p-6 pl-4 bg-neutral-100 rounded-3xl font-poppins">
              <span className="overflow-hidden overflow-ellipsis">
                {context.account}
              </span>
            </div>
          </div>
          <div className="flex-col mb-4">
            <div className="flex-row">
              <div className="mb-2 3xl-text font-bold">
                Step 2: Provide your transaction id
              </div>
              <div className="mb-2 3xl-text">
                After you have send funds to your pegin address please provide
                your transaction id
              </div>
            </div>
            <div className="flex">
              <input
                className="w-full p-2 mr-2 border border-gray-300 rounded-md"
                type="text"
                placeholder="Enter Txid"
                value={txidInput}
                onChange={(e) => setTxidInput(e.target.value)}
              />
              <button
                className="bg-blue text-black px-4 py-2 border border-gray-300 rounded-md"
                onClick={handleVerifyTxid}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const PegIn: React.FC = () => {
  return (
    <AddBotanixWrapper>
      <PeginContent />
    </AddBotanixWrapper>
  );
};

export default PegIn;
