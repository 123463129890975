import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faRotate } from '@fortawesome/free-solid-svg-icons'
import ReactDOM from 'react-dom'

interface ModalProps {
	message: string | null
	setMessage: React.Dispatch<React.SetStateAction<string | null>>
	error: string | null
	setError: React.Dispatch<React.SetStateAction<string | null>>
	isPending: boolean
}

const BackDrop: React.FC<Omit<ModalProps, 'isPending'>> = ({
	message,
	setMessage,
	error,
	setError,
}) => {
	return (
		<div
			className='fixed top-0 left-0 w-full h-full z-100 bg-black opacity-75'
			onClick={() => {
				if (message) setMessage(null)
				if (error) setError(null)
			}}
		></div>
	)
}

const addPunctuation = (sentence: string) => {
	if (sentence[sentence.length - 1] === '!') {
		return
	}
	return `.`
}

type ModalOverlayProps = ModalProps
const ModalOverlay: React.FC<ModalOverlayProps> = ({
	message,
	setMessage,
	error,
	setError,
	isPending,
}) => {
	const getMessage = () => {
		const sentences = message?.split('.')
		if (sentences) {
			return sentences.map((sentence, index) => {
				return (
					sentence !== '' && (
						<p key={index}>{`${sentence}${addPunctuation(sentence) ?? ''}`}</p>
					)
				)
			})
		}
	}

	return (
		<div className='fixed top-1/3 left-[37%] min-w-1/4  p-4 z-100 overflow-hidden bg-white rounded-3xl text-white text-center font-extrabold'>
			<div className='flex-col justify-center items-center'>
				<div className='flex justify-evenly mt-6 text-black text-base font-medium font-poppins'>
					{error && (
						<img
							src='/images/exclamation-point-black.png'
							alt='Exclamation Point'
						/>
					)}
					<div className='flex items-center'>
						{message && (
							<div className='flex items-center'>
								{isPending ? (
									<FontAwesomeIcon icon={faRotate} size='2x' />
								) : (
									<FontAwesomeIcon icon={faCheck} size='3x' />
								)}
								<div className='flex-col justify-end ml-8'>{getMessage()}</div>
							</div>
						)}
						{error && <p>Error: {error}</p>}
					</div>
				</div>
				<div className='flex w-full justify-end'>
					<button
						className='py-2 mt-6 px-4 bg-yellow rounded-xl text-neutral-600 text-xs font-poppins'
						onClick={() => {
							if (message) setMessage(null)
							if (error) setError(null)
						}}
					>
						Close
					</button>
				</div>
			</div>
		</div>
	)
}

const Modal: React.FC<ModalProps> = ({
	message,
	setMessage,
	error,
	setError,
	isPending,
}) => {
	return (
		<>
			{ReactDOM.createPortal(
				<BackDrop
					message={message}
					setMessage={setMessage}
					error={error}
					setError={setError}
				/>,
				document.getElementById('backdrop-root')!
			)}
			{ReactDOM.createPortal(
				<ModalOverlay
					message={message}
					setMessage={setMessage}
					error={error}
					setError={setError}
					isPending={isPending}
				/>,
				document.getElementById('overlay-root')!
			)}
		</>
	)
}

export default Modal
