interface AddBotanixLogoProps {
	width?: string
	height?: string
	viewBox?: string
}
const AddBotanixLogo: React.FC<AddBotanixLogoProps> = ({
	width,
	height,
	viewBox,
}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			xmlSpace='preserve'
			id='Layer_1'
			x={0}
			y={0}
			width={width || '512'}
			height={height || '512'}
			viewBox={viewBox || '0 0 512 512'}
		>
			<style>{'.st0{fill:#141414}'}</style>
			<path
				d='m443.78 126.84-49.34 69.76a8.806 8.806 0 0 1-6.02 3.63l-74.27 9.64v-9.85l50.08-50.09c5.36-5.36 8.31-12.48 8.31-20.05v-92h-19.6v92.01c0 2.34-.91 4.54-2.56 6.19l-36.22 36.22v-1.97c0-16.97-13.8-30.77-30.77-30.77h-47.06c-16.97 0-30.77 13.8-30.77 30.77v1.97l-36.22-36.22a8.698 8.698 0 0 1-2.56-6.19V37.88h-19.6v92.01c0 7.58 2.95 14.7 8.31 20.05l50.08 50.08v9.85l-74.27-9.64a8.77 8.77 0 0 1-6.02-3.63l-49.34-69.76-16.01 11.32 49.34 69.76a28.445 28.445 0 0 0 19.5 11.75l76.79 9.97v9.85c0 16.97 13.8 30.77 30.77 30.77h47.06c16.97 0 30.77-13.8 30.77-30.77v-9.85l76.79-9.97c7.83-1.02 14.94-5.3 19.5-11.75l49.34-69.76-16.01-11.32zM294.55 239.49c0 6.17-5 11.16-11.16 11.16h-47.06c-6.17 0-11.16-5-11.16-11.16v-69.16c0-6.17 5-11.16 11.16-11.16h47.06c6.17 0 11.16 5 11.16 11.16v69.16z'
				className='st0'
			/>
			<path
				d='M409.55 316.17a28.427 28.427 0 0 0-16.53-15.66l-72.86-26.21 2.11-9.62c3.64-16.57-6.88-33.01-23.45-36.65l-45.97-10.1c-16.57-3.64-33.01 6.88-36.65 23.45l-2.11 9.62-77.14-6.74a28.43 28.43 0 0 0-21.57 7.29L52.22 309.1l13.2 14.49 63.16-57.55a8.795 8.795 0 0 1 6.66-2.25l74.6 6.52-2.11 9.62-59.67 38.17c-6.38 4.08-10.79 10.41-12.42 17.8l-19.74 89.86 19.15 4.21 19.74-89.86c.5-2.28 1.86-4.24 3.83-5.5l43.15-27.6-.42 1.92c-3.64 16.57 6.88 33.01 23.45 36.65l45.97 10.1c16.57 3.64 33.01-6.88 36.65-23.45l.42-1.92 27.6 43.15a8.712 8.712 0 0 1 1.18 6.6l-19.74 89.86 19.15 4.21 19.74-89.86c1.63-7.4.27-14.99-3.81-21.37l-38.17-59.66 2.11-9.62 70.47 25.35a8.82 8.82 0 0 1 5.1 4.83l33.22 78.72 18.06-7.62-33.2-78.73zm-134.57 20.35-45.97-10.1c-6.02-1.32-9.83-7.28-8.51-13.3l14.84-67.55c1.32-6.02 7.28-9.83 13.3-8.51l45.97 10.1c6.02 1.32 9.83 7.28 8.51 13.3l-14.84 67.55c-1.32 6.03-7.28 9.84-13.3 8.51z'
				className='st0'
			/>
			<defs>
				<path
					id='SVGID_00000104681701703870271090000007739696769517547153_'
					d='M57.91 35.88v236.38h403.87V35.88H57.91zm257.24 203.61c0 10.13-4.87 19.64-13.05 25.63l1.02-4.66c1.32-6.02-2.49-11.98-8.51-13.3l-3.73-.82a10.1 10.1 0 0 0 2.66-6.85v-12.63l5.28 1.16c6.56 1.44 12.17 4.9 16.33 9.57v1.9z'
				/>
			</defs>
			<clipPath id='SVGID_00000122706003443319001980000012170517189403386036_'>
				<use
					xlinkHref='#SVGID_00000104681701703870271090000007739696769517547153_'
					style={{
						overflow: 'visible',
					}}
				/>
			</clipPath>
			<path
				d='m443.78 126.84-49.34 69.76a8.806 8.806 0 0 1-6.02 3.63l-74.27 9.64v-9.85l50.08-50.09c5.36-5.36 8.31-12.48 8.31-20.05v-92h-19.6v92.01c0 2.34-.91 4.54-2.56 6.19l-36.22 36.22v-1.97c0-16.97-13.8-30.77-30.77-30.77h-47.06c-16.97 0-30.77 13.8-30.77 30.77v1.97l-36.22-36.22a8.698 8.698 0 0 1-2.56-6.19V37.88h-19.6v92.01c0 7.58 2.95 14.7 8.31 20.05l50.08 50.08v9.85l-74.27-9.64a8.77 8.77 0 0 1-6.02-3.63l-49.34-69.76-16.01 11.32 49.34 69.76a28.445 28.445 0 0 0 19.5 11.75l76.79 9.97v9.85c0 16.97 13.8 30.77 30.77 30.77h47.06c16.97 0 30.77-13.8 30.77-30.77v-9.85l76.79-9.97c7.83-1.02 14.94-5.3 19.5-11.75l49.34-69.76-16.01-11.32zM294.55 239.49c0 6.17-5 11.16-11.16 11.16h-47.06c-6.17 0-11.16-5-11.16-11.16v-69.16c0-6.17 5-11.16 11.16-11.16h47.06c6.17 0 11.16 5 11.16 11.16v69.16z'
				style={{
					clipPath:
						'url(#SVGID_00000122706003443319001980000012170517189403386036_)',
					fill: '#141414',
				}}
			/>
		</svg>
	)
}

export default AddBotanixLogo
