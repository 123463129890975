export type Config = Readonly<{
	BOTANIX_DOCS_URL: string
	BOTANIX_LANDING_PAGE_URL: string
	BOTANIX_TESTNET_CHAID_ID: number
	SIDE_CAR_URL: string
	GENESIS_CONTRACT_ADDRESS: string
	FAUCET_CONTRACT_ADDRESS: string
	PROVIDER_URL: string
	BLOCK_EXPLORER_URL: string
}>

const BOTANIX_LANDING_PAGE_URL = 'https://botanixlabs.xyz/en/home'
const BOTANIX_DOCS_URL = 'https://docs.botanixlabs.xyz/botanix-labs'
const BOTANIX_TESTNET_CHAID_ID =
	process.env.REACT_APP_BOTANIX_TESTNET_CHAID_ID || 3636
const SIDE_CAR_URL =
	process.env.REACT_APP_SIDE_CAR_URL || 'https://sidecar.botanixlabs.dev'
const GENESIS_CONTRACT_ADDRESS =
	process.env.REACT_APP_GENESIS_CONTRACT_ADDRESS ||
	'0x0Ea320990B44236A0cEd0ecC0Fd2b2df33071e78'
const FAUCET_CONTRACT_ADDRESS =
	process.env.REACT_APP_FAUCET_CONTRACT_ADDRESS ||
	'0x07Fe378C9a9c68530297a1dEAc96ebBd1deeA018'
const PROVIDER_URL =
	process.env.REACT_APP_PROVIDER_URL || 'https://node.botanixlabs.dev'
const BLOCK_EXPLORER_URL =
	process.env.REACT_APP_BLOCK_EXPLORER_URL || 'https://blockscout.botanixlabs.dev'

const CONFIG: Config = {
	BOTANIX_LANDING_PAGE_URL,
	BOTANIX_DOCS_URL,
	BOTANIX_TESTNET_CHAID_ID: Number(BOTANIX_TESTNET_CHAID_ID),
	SIDE_CAR_URL,
	GENESIS_CONTRACT_ADDRESS,
	FAUCET_CONTRACT_ADDRESS,
	PROVIDER_URL,
	BLOCK_EXPLORER_URL,
}

export default CONFIG
