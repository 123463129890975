const FaucetSidebarSVG = () => {
	return (
		<svg
			width='544'
			height='589'
			viewBox='0 -250 544 589'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M182.357 493.437V296.146H186.356V493.437H182.357Z'
				fill='black'
			/>
			<path
				d='M246.425 493.437V296.146H250.425V493.437H246.425Z'
				fill='black'
			/>
			<path
				d='M248.371 296.182C248.371 278.504 234.021 264.154 216.335 264.154'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<path
				d='M307.042 324.149C289.364 324.149 275.014 338.499 275.014 356.185'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<path
				d='M273.007 553.437V356.146H277.006V553.437H273.007Z'
				fill='black'
			/>
			<path
				d='M337.075 553.437V356.146H341.075V553.437H337.075Z'
				fill='black'
			/>
			<path
				d='M339.021 356.182C339.021 338.504 324.671 324.154 306.985 324.154'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<path
				d='M396.042 359.149C378.364 359.149 364.014 373.499 364.014 391.185'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<path
				d='M362.007 588.437V391.146H366.006V588.437H362.007Z'
				fill='black'
			/>
			<path
				d='M426.075 588.437V391.146H430.075V588.437H426.075Z'
				fill='black'
			/>
			<path
				d='M428.021 391.182C428.021 373.504 413.671 359.154 395.985 359.154'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<path
				d='M216.392 264.149C198.714 264.149 184.364 278.499 184.364 296.185'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<line
				y1='-2'
				x2='137'
				y2='-2'
				transform='matrix(4.37114e-08 1 1 -4.37114e-08 111 287)'
				stroke='black'
				strokeWidth='4'
			/>
			<path d='M80 211L24 211L24 207L80 207L80 211Z' fill='black' />
			<path
				d='M80.0165 189L0 189L-3.49651e-07 185L80.0165 185L80.0165 189Z'
				fill='black'
			/>
			<path
				d='M101 278C101 282.418 104.582 286 109 286C113.418 286 117 282.418 117 278C117 273.582 113.418 270 109 270C104.582 270 101 273.582 101 278Z'
				stroke='black'
				strokeWidth='4'
			/>
			<path d='M140.999 0H362V3.99954H140.999V0Z' fill='black' />
			<path d='M323 0H544.001V3.99954H323V0Z' fill='black' />
			<path
				d='M106.989 114.032V34.0156H110.989V114.032H106.989Z'
				fill='black'
			/>
			<path
				d='M108.941 133.869C103.418 133.869 98.9402 129.392 98.9402 123.869C98.9402 118.345 103.418 113.868 108.941 113.868C114.464 113.868 118.941 118.345 118.941 123.869C118.941 129.392 114.464 133.869 108.941 133.869Z'
				stroke='black'
				strokeWidth='3.99388'
				strokeMiterlimit='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M141.024 2.01758C123.347 2.01758 108.997 16.3672 108.997 34.0534'
				stroke='black'
				strokeWidth='4.01389'
				strokeMiterlimit='1.5'
				strokeLinejoin='round'
			/>
			<path
				d='M98.9072 188.42V206.97H95V210.68H100.861V214.39H104.768V210.68H108.675V214.39H112.582V210.585C116.427 210.123 119.42 207.033 119.42 203.26C119.42 200.811 118.15 198.652 116.216 197.3C117.642 195.92 118.441 194.063 118.443 192.13C118.443 188.683 115.945 185.803 112.582 184.973V181H108.675V184.71H104.768V181H100.861V184.71H95V188.42H98.9072ZM111.606 206.97H102.814V199.55H111.606C113.76 199.55 115.513 201.214 115.513 203.26C115.513 205.306 113.76 206.97 111.606 206.97ZM102.814 188.42H110.629C112.784 188.42 114.536 190.084 114.536 192.13C114.536 194.176 112.784 195.84 110.629 195.84H102.814V188.42Z'
				fill='black'
			/>
		</svg>
	)
}

export default FaucetSidebarSVG
