import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Main from './components/ui/Main'
import { AppContextProvider } from './store/AppContextProvider'

const Home = () => (
	<AppContextProvider>
		<Main />
	</AppContextProvider>
)

const router = createBrowserRouter([{ path: '/*', element: <Home /> }])

function App() {
	return <RouterProvider router={router} />
}

export default App
