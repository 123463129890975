import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import Twitter from "../svgs/Twitter";
import { IconWrapper } from "./IconWrapper";
import Discord from "../svgs/Discord";
import Telegram from "../svgs/Telegram";
import LinkedIn from "../svgs/LinkedIn";
import ModalContext from "../../store/ModalContextProvider";

const Maintenance = () => {
	const [email, setEmail] = useState<string>("");
	const [buttonText, setButtonText] = useState<string>("Notify Me!");
	const modalContext = useContext(ModalContext);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!email) {
			return;
		}
		try {
			const response = await fetch(
				"https://api.sendx.io/api/v1/team/contact/identify",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						name: "application/json",
						value: "pFH77EHdtgic22TZxbyG",
						Origin: "https://bridge.botanixlabs.dev/",
					},
					body: JSON.stringify({
						Email: email,
						subscribe: "",
					}),
				}
			);
			if (response.ok) {
				// Send email to our server here
				setButtonText("Sent!");
				setTimeout(() => {
					setButtonText("Notify Me!");
				}, 3000);
				setEmail("");
			}
		} catch (error) {
			modalContext.setError("Failed to send");
		}
	};
	return (
		<div className="flex flex-col">
			<FontAwesomeIcon
				icon={faScrewdriverWrench}
				size="2xl"
				style={{ color: "#050505" }}
			/>
			<h1 className="text-center mt-4 sm:text-xl text-xl md:text-4xl font-grotesk">
				Testnet under maintenance
			</h1>

			<div className="text-center md:text-lg text-sm  font-grotesk  mt-5">
				We are working on the next version of the Federation.
				{/* Sign
				up and be the first one to be notified when the bridge is accessible again. */}
			</div>
			{/* form commented out pending when the send x service starts working properly */}

			<div className=" mt-5 mb-4">
				<div className="h-[1px] w-full bg-[#DDD] dark:bg-black" />
				<div className="h-[1px] w-full bg-white dark:bg-opacity-10" />
				<form onSubmit={handleSubmit}>
					<div className=" flex justify-between py-1">
						<input
							id="SubscriberForm-Email-NsBdWTc9qEqBd5d5coXu10"
							className="w-full border-none bg-white py-0 pl-0 pr-5 focus:outline-none focus:ring-0 dark:bg-[#0F1014]"
							type="email"
							name="Email"
							placeholder="Enter Your Email Here"
							aria-label="Email"
							data-attr-type="0"
							value={email}
							required
							onChange={(e) => setEmail(e.target.value)}
						/>

						<button
							className="bg-yellow flex font-grotesk   shrink-0 cursor-pointer text-lg p-2 items-center justify-center rounded-xl h-12"
							type="submit"
							name="subscribe"
						>
							{buttonText}
						</button>
					</div>
				</form>

				<div className="h-[1px] w-full bg-[#DDD] dark:bg-black" />
				<div className="h-[1px] w-full bg-white dark:bg-opacity-10" />
			</div>

			<div className="flex gap-8 mx-auto mt-5">
				<IconWrapper url="https://x.com/botanixlabs">
					<Twitter />
				</IconWrapper>

				<IconWrapper url="https://discord.gg/botanixlabs">
					<Discord />
				</IconWrapper>
				<IconWrapper url="https://t.me/+sp8J07DEGTxmNjc0">
					<Telegram />
				</IconWrapper>
				<IconWrapper url="https://www.linkedin.com/company/botanix-labs/">
					<LinkedIn />
				</IconWrapper>
			</div>
		</div>
	);
};

export default Maintenance;
