import BotanixLogo from "../BotanixLogo";
import FeedbackButton from "../FeedbackButton";

interface SideBarProps {
	sideBarTitle: string;
	className?: string;
}

const SideBar: React.FC<SideBarProps> = ({ sideBarTitle, className }) => {
	return (
		<div className={className}>
			<div className="flex flex-col h-full">
				<div className="flex flex-col basis-1/2 m-10 mt-6 justify-between">
					<BotanixLogo />
					<div className="mb-24">
						<span className="text-black xl:text-3xl 2xl:text-4xl min-[1690px]:text-5xl font-semibold font-grotesk">
							Botanix
						</span>
						<span className="text-black xl:text-3xl 2xl:text-4xl min-[1690px]:text-5xl font-normal font-grotesk">
							{" "}
							{sideBarTitle}
						</span>
					</div>
				</div>
				<div className="flex flex-col basis-1/2 justify-end w-1/4 ml-10">
					<FeedbackButton />
				</div>
			</div>
		</div>
	);
};

export default SideBar;
