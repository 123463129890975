const RightArrow: React.FC = () => {
	return (
		<svg
			width='25'
			height='22'
			viewBox='0 0 25 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				id='Vector'
				d='M14.2786 22C18.4655 17.7042 20.813 15.2958 25 11L14.2821 -9.70363e-07L13.0196 1.29249L21.5821 10.0833L8.65562e-07 10.0833L1.02583e-06 11.9167L21.5821 11.9166L13.0161 20.7112L14.2786 22Z'
				fill='black'
			/>
		</svg>
	)
}

export default RightArrow
