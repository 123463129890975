const FeedbackButton: React.FC = () => {
	return (
		<a
			href='https://9wh6z2w76to.typeform.com/to/r1GCKfq9'
			className='flex justify-around items-center mb-4 py-3 border border-black rounded-2xl'
			target='_blank'
			rel='noreferrer'
		>
			<span className='text-neutral-600'>Feedback</span>
		</a>
	)
}

export default FeedbackButton
