import React, { useState } from 'react'
import { Children } from '../types/types'

interface Context {
	message: string | null
	setMessage: React.Dispatch<React.SetStateAction<string | null>>
	error: string | null
	setError: React.Dispatch<React.SetStateAction<string | null>>
	isPending: boolean
	setIsPending: React.Dispatch<React.SetStateAction<boolean>>
}
const ModalContext = React.createContext<Context>({
	message: null,
	setMessage: () => {},
	error: null,
	setError: () => {},
	isPending: false,
	setIsPending: () => {},
})

export const ModalContextProvider: React.FC<Children> = ({ children }) => {
	const [message, setMessage] = useState<string | null>(null)
	const [error, setError] = useState<string | null>(null)
	const [isPending, setIsPending] = useState<boolean>(false)

	const contextValue = {
		message,
		setMessage,
		error,
		setError,
		isPending,
		setIsPending,
	}

	return (
		<ModalContext.Provider value={contextValue}>
			{children}
		</ModalContext.Provider>
	)
}

export default ModalContext
