const CopyFull: React.FC = () => {
	return (
		<svg
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.07679 1H7.92321C8.74365 1.00449 9.52881 1.33475 10.1058 1.91812C10.6828 2.50149 11.0044 3.29019 11 4.1107V7.8893C11.0044 8.70974 10.6828 9.49849 10.1058 10.0819C9.52881 10.6652 8.74365 10.9954 7.92321 11H4.07679C3.25629 10.9954 2.47117 10.6652 1.89417 10.0819C1.31716 9.49849 0.995543 8.70974 1.00005 7.8893V4.11159C0.995302 3.29093 1.31682 2.502 1.89385 1.91843C2.47088 1.33486 3.25614 1.00449 4.07679 1Z'
				stroke='black'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5 13H9.08859C11.2731 12.9751 13.024 11.1844 12.9998 9V5'
				stroke='black'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default CopyFull
