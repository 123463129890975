import { useContext } from "react";
import AddBotanixLogo from "../svgs/AddBotanixLogo";
import MetamaskLogo from "../svgs/MetamaskLogo";
import RightArrow from "../svgs/RighArrowSVG";
import Wand from "../svgs/Wand";
import AppContext from "../../store/AppContextProvider";
import Maintenance from "../maintanace";

export const getBackgroundColor = (account: string, gatewayAddress: string) => {
	if (!account) {
		return "bg-yellow-400";
	}
	if (!gatewayAddress) {
		return "bg-neutral-100";
	}
	return "bg-white";
};

interface AddBotanixButtonWrapper {
	isFaucet?: boolean;
	children?: React.ReactNode;
}
const AddBotanixWrapper: React.FC<AddBotanixButtonWrapper> = ({
	isFaucet,
	children,
}) => {
	const context = useContext(AppContext);

	const faucetClickHandler = () => {
		if (!context.account) {
			context.addBotanixTestnetChain();
		}
	};
	const bridgeClickHandler = () => {
		// @ts-ignore
		const grecaptcha = window.grecaptcha;

		if (context.account) {
			grecaptcha.enterprise.ready(async () => {
				const token = await grecaptcha.enterprise.execute(
					"6LefRlkpAAAAAEpRAh_VLD6eaRwVbDOksvELWaNN",
					{ action: "submit" }
				);
				context.getGatewayAddress(token);
			});
		} else {
			context.addBotanixTestnetChain();
		}
	};

	interface ButtonProps {
		clickHandler: () => void;
	}
	const Button: React.FC<ButtonProps> = ({ clickHandler }) => {
		return (
			<button className="w-full h-full" onClick={clickHandler}>
				<div className="flex items-center">
					{context.account ? (
						<MetamaskLogo />
					) : (
						<div className="ml-10">
							<AddBotanixLogo viewBox="0 0 512 512" width="64" height="147" />
						</div>
					)}
					<div
						className={`flex w-2/3 justify-between ${context.account ? "mt-6" : ""}`}
					>
						<div className="flex w-full justify-between">
							<div className="ml-10 font-poppins">
								{context.account ? "Show Deposit Address" : "Add Botanix Testnet First"}
							</div>
							{context.account ? <Wand /> : <RightArrow />}
						</div>
					</div>
				</div>
			</button>
		);
	};

	return (
		<div className="bg-white p-10 w-full rounded-3xl shadow-2xl">
			<Maintenance />
			{/* the code below is commented out because we want to shut down the bridge pending the time that v1 is ready to go live  */}

			{/* <div
				className={`rounded-3xl ${getBackgroundColor(
					context.account,
					context.gatewayAddress
				)}`}
			> */}
			{/* Faucet Button */}
			{/* {isFaucet && !context.account && (
					<Button clickHandler={faucetClickHandler} />
				)} */}
			{/* Bridge Button */}
			{/* {!isFaucet && !context.gatewayAddress && (
					<Button clickHandler={bridgeClickHandler} />
				)}
				{!isFaucet && context.gatewayAddress && children}
				{isFaucet && context.account && children}
			</div> */}
		</div>
	);
};

export default AddBotanixWrapper;
