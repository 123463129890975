import config from "../../config";
import HorizontalLogo from "../svgs/HorizontalLogo";

interface BotanixLogoProps {
	isTablet?: boolean;
}

const BotanixLogo: React.FC<BotanixLogoProps> = ({ isTablet }) => {
	return (
		<div
			className={`flex w-full justify-center md:justify-between items-center ${
				isTablet ? "xl:hidden" : ""
			}`}
		>
			<a href={config.BOTANIX_LANDING_PAGE_URL} target="_blank" rel="noreferrer">
				<HorizontalLogo isTablet={isTablet ?? false} />
			</a>
		</div>
	);
};

export default BotanixLogo;
