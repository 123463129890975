import { useContext } from "react";
import PegIn from "./PegIn";
import PegOut from "./PegOut";
import AppContext from "../../../store/AppContextProvider";
import Steps from "./Steps";
import SuccessPage from "./SuccessPage";
import config from "../../../config";
import FeedbackButton from "../FeedbackButton";

const Bridge: React.FC = () => {
	const context = useContext(AppContext);

	const isConnectedToBotanixTestnet =
		context.chainId === config.BOTANIX_TESTNET_CHAID_ID;

	const isPeggingIn = !context.isSuccess && context.toggle;

	return (
		<div
			className={`flex flex-col ${
				!isConnectedToBotanixTestnet ? "mt-20" : ""
			} ${!context.gatewayAddress ? "justify-around" : ""}`}
		>
			{!context.toggle && context.gatewayAddress && <Steps />}
			<div
				className={`flex justify-center w-full ${
					!isPeggingIn ? "mt-10" : ""
				} xl:mt-15`}
			>
				{context.isSuccess ? (
					<SuccessPage />
				) : !context.toggle ? (
					<PegIn />
				) : (
					<PegOut />
				)}
			</div>
			<div className={`flex justify-start w-full mt-3 lg:mt-6`}>
				<div className="flex flex-col justify-end md:w-1/6 w-1/4 xl:hidden">
					<FeedbackButton />
				</div>
			</div>
		</div>
	);
};

export default Bridge;
