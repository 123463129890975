import { useContext } from 'react'
import AppContext from '../../../store/AppContextProvider'
import AddBotanixLogo from '../../svgs/AddBotanixLogo'
import RightArrow from '../../svgs/RighArrowSVG'
import { BigNumber, ethers } from 'ethers'

const PegOut: React.FC = () => {
	const context = useContext(AppContext)

	// only show 8 decimal places
	const formatAmount = (amount: string) => {
		const decimalCount = amount.split('.')[1]?.length ?? 0
		if (decimalCount > 8) {
			return parseFloat(amount.slice(0, amount.length - (decimalCount - 8)))
		}
		return amount
	}

	return (
		<div className='flex-col w-full'>
			<div className='flex-col bg-white p-10 rounded-3xl shadow-2xl'>
				<div className='flex-col'>
					<div className='mb-2 text-label'>Route</div>
					<div className='flex justify-between items-center'>
						<div className='flex w-[45%] h-16 items-center bg-neutral-100 rounded-3xl'>
							<div className='ml-6'>
								<AddBotanixLogo viewBox='0 0 512 512' width='32' height='147' />
							</div>
							<p className='ml-10 text-label'>Botanix</p>
						</div>
						<div className='text-label'>To</div>
						<div className='flex w-[45%] h-16 items-center bg-neutral-100 rounded-3xl'>
							<img
								className='ml-6'
								src='/images/bitcoin-logo.png'
								alt='Bitcoin Logo'
							/>
							<p className='ml-10 text-label'>Bitcoin Signet</p>
						</div>
					</div>
				</div>
				<div className='flex-col mt-8'>
					<div className='flex justify-between items-center'>
						<div className='mb-2 text-label'>Amount</div>
						<div className='flex items-center mb-2 text-neutral-600 text-sm font-light font-heebo'>
							Available:{' '}
							<p className='ml-2 text-label'>
								{formatAmount(context.balance)} BTC
							</p>
						</div>
					</div>
					<div className='flex justify-between h-16 items-center bg-neutral-100 rounded-3xl'>
						<input
							className='w-1/2 ml-6 bg-neutral-100 text-neutral-600 text-lg font-normal font-poppins'
							placeholder='Enter Amount'
							type='number'
							min='0'
							step='0.00005'
							value={formatAmount(context.withdrawAmount)}
							onChange={(event) =>
								context.setWithdrawAmount(event.currentTarget.value)
							}
						/>
						<div className='flex items-center mr-4'>
							<button
								className='ml-2 p-1 bg-yellow rounded-lg text-neutral-600 text-base font-medium font-poppins'
								type='button'
								onClick={(event) => {
									const balanceWei = ethers.utils.parseEther(context.balance)
									const maxAmount = BigNumber.from(balanceWei)
										.sub(context.withdrawCost)
										.toString()
									context.setWithdrawAmount(ethers.utils.formatEther(maxAmount))
								}}
							>
								max
							</button>
							<div className='w-[30px] h-[0px] rotate-90 border border-black border-opacity-20'></div>
							<div className='text-neutral-600 text-base font-normal font-poppins'>
								BTC
							</div>
						</div>
					</div>
					{context.validateMessage !== 'success' ? (
						<div className='flex items-center h-10 pl-4 bg-red-400 rounded-3xl text-white'>
							{context.validateMessage}
						</div>
					) : null}
				</div>
				<div className='flex-col mt-8'>
					<div className='flex items-center'>
						<div className='mb-2 text-label'>Receive Address</div>
					</div>
					<div className='flex justify-between h-16 items-center bg-neutral-100 rounded-3xl'>
						<input
							className='w-full bg-neutral-100 ml-6 text-neutral-600 text-lg font-normal font-poppins'
							placeholder='Enter BTC receive address'
							value={context.receiveAddress}
							onChange={(event) =>
								context.receiveAddressHandler(event.currentTarget.value)
							}
						/>
					</div>
				</div>
				<div className='flex-col mt-8'>
					<div className='flex justify-between items-center'>
						<div className='flex items-center mb-2 text-neutral-600 text-sm font-light font-heebo'>
							You will receive:{' '}
							<p className='ml-2 text-label'>
								{formatAmount(context.withdrawAmount)} BTC
							</p>
						</div>
						<button
							className={`flex justify-around items-center w-[40%] p-4 ${
								context.validateMessage === 'success'
									? 'bg-amber-400'
									: 'bg-neutral-200'
							} rounded-3xl`}
							disabled={context.validateMessage !== 'success'}
							onClick={() => context.withdrawFunds()}
						>
							<div className='text-label'>WITHDRAW</div>
							<RightArrow />
						</button>
					</div>
				</div>
			</div>
			<div className='flex-col mt-10 bg-white p-10 rounded-3xl shadow-2xl'>
				<div className='flex justify-between text-label'>
					<div>Fees</div>
					<div>{context.btcFees} Sats/Vb</div>
				</div>
			</div>
		</div>
	)
}

export default PegOut
